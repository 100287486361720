<template>
  <div
    id="SearchResultsContainer"
    class="list-group"
    v-if="showResults || anySelected"
    :class="showResults ? 'expand-full' : anySelected ? 'expand-one' : ''"
  >
    <!-- Port objects here -->
    <SearchResultItem
      v-for="(item, index) in geoJSON.features"
      :properties="item.properties"
      :hashID="item.hashID"
      :coordinates="item.geometry.coordinates"
      :key="index"
      @search-item-mouse-over="mouseOverItem"
      @search-item-mouse-leave="mouseLeaveItem"
      @search-item-on-click="mouseClickItem"
    />
  </div>
  <div class="footer">
    <button
      v-if="showResults && geoJSON.features.length > 0"
      class="btn fa fa-chevron-up list-arrow"
      @click="toggleDropdown"
    /><button
      v-if="!showResults && geoJSON.features.length > 0"
      class="btn fa fa-chevron-down list-arrow"
      @click="toggleDropdown"
    />
  </div>
</template>

<script>
import SearchResultItem from "./SearchResultsItem";

export default {
  name: "SearchResults",
  components: {
    SearchResultItem,
  },
  computed: {
    anySelected() {
      return this.geoJSON.features.some(
        (feature) => feature.properties.selected
      );
    },
  },
  data() {
    return {
      showResults: true,
    };
  },
  emits: [
    "search-item-mouse-over",
    "search-item-mouse-leave",
    "search-item-on-click",
    "scroll-in-view",
  ],
  inject: ["geoJSON"],
  methods: {
    mouseOverItem(hashID) {
      this.$emit("search-item-mouse-over", hashID);
    },
    mouseLeaveItem(hashID) {
      this.$emit("search-item-mouse-leave", hashID);
    },
    mouseClickItem(hashID) {
      this.$emit("search-item-on-click", hashID);
    },
    scrollIntoView() {
      var item = this.geoJSON.features.find(
        (feature) => feature.properties.selected
      );
      this.$emit("scroll-in-view", item.hashID);
    },
    toggleDropdown() {
      this.showResults = !this.showResults;
      if (this.anySelected) {
        this.scrollIntoView()
      }
    },
  },
};
</script>

<style>
#SearchResultsContainer {
  overflow: scroll;
  z-index: 98;
  border-radius: 0 0 5px 5px;
}
#SearchResultsContainer::-webkit-scrollbar {
  display: none;
}
/* #SearchResultsContainer:hover::-webkit-scrollbar {
  display: inline;
} */
.expand-full {
  max-height: 30vh;
}
.expand-one {
  max-height: 80px;
}
.list-arrow {
  z-index: 98;
}
</style>
