<template>
  <li
    @mouseover="mouseOver(hashID)"
    @mouseleave="mouseLeave(hashID)"
    @click="mouseClick(hashID)"
    type="button"
    :class="[
      properties.selected ? 'active scroll-to-me' : '',
      properties.hover ? 'hover' : '',
      'list-group-item',
      'list-group-item-action',
      'search-list-item',
    ]"
    :id="`item-${hashID}`"
    aria-current="true"
  >
    <div class="d-flex w-100 align-items-center">
      <div class="d-flex list-icon px-1 px-md-2 align-items-center">
        <Icon :type="icon_type(properties)" class="text-dark" />
      </div>
      <div class="d-flex flex-column w-100 align-items-start item-info">
        <div class="d-flex flex-row w-100">
          <h5
            v-if="properties.source === 'ourairports'"
            class="item-title text-truncate mb-0 mt-2"
          >
            {{ properties.name }}, <small>{{ properties.municipality }}</small>
          </h5>
          <h5
            v-if="properties.source === 'unlocode'"
            class="item-title text-truncate mb-0 mt-2"
          >
            {{ properties.name }}
          </h5>
        </div>
        <div class="d-flex flex-row text-truncate">
          <small class="">{{ properties.country.name }} </small>
          <small class="d-none d-sm-block" v-if="properties.region"
            >, {{ properties.region.name }}</small
          >
        </div>
        <div
          class="d-flex flex-row flex-nowrap w-100 align-items-center justify-content-between text-truncate"
        >
          <div v-if="properties.source === 'ourairports'" class="">
            <abbr v-if="properties.iata">{{ properties.iata }}</abbr>
            <abbr v-if="properties.iata && properties.gps_code"> | </abbr>
            <abbr v-if="properties.gps_code">{{ properties.gps_code }}</abbr>
          </div>
          <div v-if="properties.source === 'unlocode'" class="">
            <abbr>{{ properties.un_locode }}</abbr>
            <abbr v-if="properties.iata"> | {{ properties.iata }}</abbr>
          </div>
          <FunctionIcons :properties="properties"/>
          <div class="d-none d-sm-block">
            <span class="fa fa-globe-europe" /><small class="px-1">
              {{ printCoordinates(coordinates) }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import Icon from "./Icon.vue";
import FunctionIcons from "./SearchResultsItemFunctionIcons.vue";
import {icon_type} from "../utils.js";

export default {
  name: "SearchResultsItem",
  components: {
    Icon,
    FunctionIcons,
  },
  emits: [
    "search-item-mouse-over",
    "search-item-mouse-leave",
    "search-item-on-click",
  ],
  props: ["properties", "hashID", "coordinates"],
  methods: {
    mouseOver(hashID) {
      this.$emit("search-item-mouse-over", hashID);
    },
    mouseLeave(hashID) {
      this.$emit("search-item-mouse-leave", hashID);
    },
    mouseClick(hashID) {
      this.$emit("search-item-on-click", hashID);
    },
    printCoordinates(array) {
      if (array) {
        return `${array[0].toFixed(4)}, ${array[1].toFixed(4)}`;
      }
      return "unknown";
    },
    icon_type: icon_type,
  },
};
</script>

<style>
[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: none;
}
.search-list-item {
  height: 80px;
  padding: 0;
}
.item-info {
  overflow: hidden;
}
.item-title {
  text-overflow: clip;
  text-align: left;
}
.list-icon {
  max-width: 15vw;
  height: 72px;
  width: 72px;
}
li.active > div > div > .icon {
  filter: invert(100%);
}
</style>
