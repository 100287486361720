<template>
  <Intro />
  <SearchBar
    ref="searchComponent"
    @search-item-mouse-over="set_hover"
    @search-item-mouse-leave="reset_hover"
    @search-item-on-click="list_set_selected"
    @scroll-in-view="scroll_to_element"
    @partial-search="partial_search"
    @full-search="full_search"
    @clear-list="clear_features"
    @allow-map-search="set_map_search"
    :loading="loading"
    :notFound="notFound"
  />
  <Map
    ref="mapComponent"
    :tooManyResults="tooManyResults"
    :mapSearch="mapSearch"
    @map-item-on-click="set_selected"
    @map-item-mouse-over="set_hover"
    @map-item-mouse-left="reset_hover"
    @map-move="map_move"
  />
</template>

<script>
import axios from "axios";
import Intro from "./components/Intro.vue";
import Map from "./components/Map.vue";
import SearchBar from "./components/SearchBar.vue";
import { reactive } from "@vue/reactivity";
import { type_sort } from "./utils.js";

export default {
  name: "App",
  components: {
    Intro,
    Map,
    SearchBar,
  },
  data() {
    return {
      loading: false,
      notFound: false,
      mapSearch: true,
      tooManyResults: false,
      geoJSON: reactive({
        type: "FeatureCollection",
        features: [],
      }),
    };
  },
  methods: {
    sortGeoJSON(a, b) {
      if (
        a.properties.match_level != null &&
        b.properties.match_level != null
      ) {
        return a.properties.match_level < b.properties.match_level ? -1 : 1;
      } else if (a.properties.match_level != null) {
        return -1;
      } else if (b.properties.match_level != null) {
        return 1;
      } else {
        return type_sort(a.properties, b.properties);
      }
    },
    update_geoJSON(updatedGeoJSON) {
      if (updatedGeoJSON.features.length > 100) {
        this.tooManyResults = true;
        return;
      }
      this.tooManyResults = false;
      let updatedFeatures = updatedGeoJSON.features.map((feature) => {
        return {
          ...feature,
          hashID: (
            feature.properties.id +
            feature.properties.source
          ).hashCode(),
        };
      });
      let updatedHashes = updatedFeatures.map((i) => i.hashID);
      let currentHashes = this.geoJSON.features.map((f) => f.hashID);
      var newFeatures = updatedFeatures.filter(
        (item) => !currentHashes.includes(item.hashID)
      );
      this.geoJSON.features = this.geoJSON.features
        .filter((item) => updatedHashes.includes(item.hashID))
        .concat(newFeatures)
        .sort(this.sortGeoJSON);
    },
    list_set_selected(hashID) {
      this.set_selected(hashID);
      var item = this.geoJSON.features.find(
        (feature) => feature.hashID === hashID
      );
      this.$refs.mapComponent.pan_to(item.geometry.coordinates);
    },
    set_selected(hashID) {
      this.geoJSON.features.map((feature) => {
        if (feature.hashID === hashID) {
          if (!feature.properties.selected) {
            this.scroll_to_element(hashID);
          }
          return (feature.properties.selected = !feature.properties.selected);
        }
        return (feature.properties.selected = false);
      });
    },
    set_hover(hashID) {
      this.geoJSON.features.map((feature) => {
        if (feature.hashID == hashID) {
          return (feature.properties.hover = true);
        } else {
          return (feature.properties.hover = false);
        }
      });
    },
    reset_hover(hashID) {
      var index = this.geoJSON.features.findIndex(
        (feature) => feature.hashID === hashID
      );
      this.geoJSON.features[index].properties.hover = false;
    },
    test_call_from_parent() {
      console.log("Hello");
    },
    set_loading() {
      this.loading = true;
    },
    reset_loading(found = true) {
      this.loading = false;
      this.notFound = !found;
    },
    map_move(bounds) {
      this.get_in_bbox(bounds);
    },
    set_map_search(state) {
      this.mapSearch = state;
    },
    validateStatus(status) {
      return (status >= 200 && status < 300) || status == 404; // added 404
    },
    scroll_to_element(hashID, behavior = "smooth") {
      // To prevent fires before list is expanded so impossible to get into view
      setTimeout(() => {
        const el = document.getElementById(`item-${hashID}`);
        if (el) {
          el.scrollIntoView({ behavior: behavior });
        }
      }, 10);
    },
    scroll_to_selected(behavior = "smooth") {
      const item = this.geoJSON.features.find(
        (feature) => feature.properties.selected
      );
      if (item) {
        this.scroll_to_element(item.hashID, behavior);
      }
    },
    clear_features() {
      this.geoJSON.features.length = 0;
    },
    debounce(func, wait) {
      let timeout;

      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    },
    partial_search(dict) {
      this.debounced_partial(dict);
    },
    full_search(dict) {
      if (this.loading) {
        return;
      }
      this.set_loading();
      if (dict.category === "custom") {
        axios
          .post("https://port-api.com/port/search", {
            search_string: dict.input,
            allowed_search_types: Array.from(
              this.$refs.searchComponent.customInput
            ),
          })
          .then((r) => {
            this.reset_loading();
            // TODO catch 404 and show in search bar
            this.update_geoJSON(r.data);
            setTimeout(() => {
              this.$refs.mapComponent.reset_map();
            }, 10);
          })
          .catch((err) => {
            this.reset_loading(false);
            console.error(err);
          });
        return;
      }
      axios
        .get(`https://port-api.com/${dict.category}/search/${dict.input}`)
        .then((r) => {
          this.reset_loading();
          // TODO catch 404 and show in search bar
          this.update_geoJSON(r.data);
          setTimeout(() => {
            this.$refs.mapComponent.reset_map();
          }, 10);
        })
        .catch((err) => {
          this.reset_loading(false);
          console.error(err);
        });
    },
    get_in_bbox(bounds) {
      if (!this.mapSearch) {
        return;
      }
      this.set_loading();
      if (this.$refs.searchComponent.searchCategory === "custom") {
        axios
          .post("https://port-api.com/port/bbox", {
            bounding_box: [
              bounds._southWest.lng,
              bounds._southWest.lat,
              bounds._northEast.lng,
              bounds._northEast.lat,
            ],
            allowed_search_types: Array.from(
              this.$refs.searchComponent.customInput
            ),
          })
          .then((r) => {
            this.reset_loading();
            this.update_geoJSON(r.data);
            this.scroll_to_selected("auto");
          })
          .catch((err) => {
            this.reset_loading(false);
            console.error(err);
          });
        return;
      }
      var category = this.$refs.searchComponent.searchCategory;
      axios
        .get(
          `https://port-api.com/${category}/bbox/${bounds._southWest.lng}/${bounds._southWest.lat}/${bounds._northEast.lng}/${bounds._northEast.lat}`
        )
        .then((r) => {
          this.reset_loading();
          this.update_geoJSON(r.data);
          this.scroll_to_selected("auto");
        })
        .catch((err) => {
          this.reset_loading(false);
          console.error(err);
        });
    },
  },
  provide() {
    return {
      geoJSON: this.geoJSON,
      loading: this.loading,
      tooManyResults: this.tooManyResults,
    };
  },
  beforeCreate() {
    String.prototype.hashCode = function() {
      var hash = 0,
        i,
        chr;
      if (this.length === 0) return hash;
      for (i = 0; i < this.length; i++) {
        chr = this.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    };
  },
  mounted() {
    this.debounced_partial = this.debounce((dict) => {
      if (!this.loading) {
        this.set_loading();
        if (dict.category === "custom") {
          axios
            .post(`https://port-api.com/port/suggest`, {
              search_string: dict.input,
              allowed_search_types: Array.from(
                this.$refs.searchComponent.customInput
              ),
            })
            .then((r) => {
              this.reset_loading();
              this.update_geoJSON(r.data);
            })
            .catch((err) => {
              this.reset_loading(false);
              console.error(err);
            });
          return;
        }
        axios
          .get(`https://port-api.com/${dict.category}/suggest/${dict.input}`)
          .then((r) => {
            this.reset_loading();
            this.update_geoJSON(r.data);
          })
          .catch((err) => {
            this.reset_loading(false);
            console.error(err);
          });
      }
    }, 25);
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}
</style>
