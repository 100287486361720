<template>
  <div id="Intro" class="container">
    <div class="row justify-content-center py-3">
      <div class="col-10 col-md-6">
        <img
          src="../assets/logo_with_text.svg"
          alt="Port API logo"
          class="w-100"
        />
      </div>
    </div>
    <h1 hidden >Port API, a free airport and UN/LOCODE search engine</h1>
    <div class="row justify-content-center">
      <div class="col-11 col-xl-9">
        <p class="mx-sm-5">
          Welcome to the port API. This free service allows you to
          programmatically access search services on two port related databases.
          The port API was created as a project to explore the asynchronous
          posibilities of the fastapi framework and is therefore not regularly
          maintained.
        </p>
        <p>
          <b>Use this service at your own risk, no warrenty of any kind!</b>
        </p>
        <small>
          <p class="mx-sm-5">
            The port API exists of two datasets. The airports collection that is
            used is from ourairports.com, which is publicly available and
            updated daily. More information about this data source can be found
            <a
              href="https://ourairports.com/data/"
              class="text-dark"
              target="_blank"
              rel="noopener noreferrer"
              >here</a
            >. The seaports come from the United Nations Code for Trade and
            Transport Locations, commonly more known as "UN/LOCODE", which also
            includes locations with other functions like rail and road
            terminals. This collection is maintained by UNECE and updated twice
            yearly. More infomation can be found
            <a
              href="https://unece.org/trade/uncefact/unlocode"
              class="text-dark"
              target="_blank"
              rel="noopener noreferrer"
              >here</a
            >. Read to openapi documentation, to explore all available endpoints
            with their options, or try it using the swagger ui.
          </p>
          <h2 class="h4">Demo port search application</h2>
          <p class="mx-sm-5">
            Below, an example application is shown that consumes the API. You
            can elastically search ports in some of the preset categories or set
            your own custom filter. Some of the icons that are used in this app
            are directly or modified from
            <a
              href="https://fontawesome.com/"
              class="text-dark"
              target="_blank"
              rel="noopener noreferrer"
              >font-awesome</a
            >. <br />
            <b>Happy searching!</b>
          </p>
        </small>

        <!-- <p class="mx-sm-5">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p> -->
      </div>
    </div>
    <div class="row justify-content-center my-2">
      <div class="col-12 col-md-8">
        <div class="row justify-content-around">
          <a
            class="col-8 col-sm-5 text-center text-light btn btn-custom btn-custom-blue"
            href="/docs"
            >Test it out</a
          >
          <a
            class="col-8 col-sm-5 text-center text-light btn btn-custom btn-custom-pink"
            href="/redoc"
            >Documentation</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Intro",
};
</script>

<style>
#Intro {
  margin-bottom: 40px;
  padding-bottom: 5px;
}
.btn-custom {
  height: 40px;
  border-radius: 20px;
}
.btn-custom-pink {
  background-image: linear-gradient(#dc30b6, #fc466b);
  background-repeat: no-repeat;
}
.btn-custom-blue {
  background-image: linear-gradient(#3f5efb, #8a51eb);
  background-repeat: no-repeat;
}
</style>
