export let type_order = [
  "seaport",
  "multi_modal",
  "airport",
  "rail_terminal",
  "border_crossing",
  "postal_exchange_office",
  "fixed_transport",
  "road_terminal",
];

const type_points = new Map([
  ["large_airport", 50],
  ["medium_airport", 25],
  ["small_airport", 10],
  ["heliport", 8],
  ["seaplane_base", 5],
  ["balloonport", 3],
  ["closed", 1],
  ["scheduled_service", 10],
  ["seaport", 10],
  ["multi_modal", 20],
  ["rail_terminal", 8],
  ["airport", 25],
  ["border_crossing", 5],
  ["postal_exchange_office", 3],
  ["fixed_transport", 8],
  ["road_terminal", 2],
  ["unknown", 1],
  ["trade_or_transport_location", 0],
]);

const type_score = function(properties) {
  let score = properties.functions.reduce((acc, cur) => acc + type_points.get(cur), 0)
  return score + type_points.get(properties.type)
}

export function type_sort(a_properties, b_properties) {
  let a_score = type_score(a_properties)
  let b_score = type_score(b_properties)
  return a_score < b_score ? 1 : -1;
}

export function icon_type(properties) {
  if (properties.source === "ourairports") {
    return properties.type;
  }
  return most_relevant_type(properties.functions);
}

export function most_relevant_type(location_functions) {
  for (const type of type_order) {
    if (location_functions.includes(type)) {
      if (type === "airport") {
        return "large_airport";
      }
      return type;
    }
  }
  return "unknown";
}

export function snake_case(input_string) {
  return input_string.toLowerCase().replace(/([^a-zA-Z])/g, "_");
}

export function kebab_case(input_string) {
  return input_string.toLowerCase().replace(/([^a-zA-Z])/g, "-");
}
