<template>
  <div v-if="properties.source === 'unlocode'" class="d-flex px-1 px-md-0">
    <div
      v-for="(location_type, index) in type_order"
      :key="index"
      :class="[
        'functions-icon',
        snake_case_functions.includes(location_type) ? '' : 'd-none d-md-block',
      ]"
    >
      <Icon
        :type="location_type"
        class="text-dark"
        :hidden="!snake_case_functions.includes(location_type)"
      />
    </div>
  </div>
  <div v-if="properties.source === 'ourairports'" class="d-flex px-1 px-md-0">
    <div v-if="properties.functions.includes('scheduled_service')" class="functions-icon"><span class="fa fa-calendar-check"></span></div>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
import { type_order, snake_case } from "../utils.js";

export default {
  components: { Icon },
  name: "FunctionIcons",
  data() {
    return { type_order: type_order };
  },
  props: ["properties"],
  computed: {
    snake_case_functions() {
      return this.properties.functions.map((f) => snake_case(f));
    },
  },
};
</script>

<style>
.functions-icon {
  width: 1rem;
  height: 1rem;
  font-size: small;
}
</style>
