<template>
  <span :class="['icon', getClass()]" />
</template>

<script>
import "../icons.css";
import { kebab_case } from "../utils.js";

export default {
  name: "Icon",
  props: ["type"],
  methods: {
    getClass() {
      return "icon-" + kebab_case(this.type);
    },
  },
};
</script>

<style></style>
