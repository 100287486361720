<template>
  <div class="container-fluid position-absolute">
    <div
      id="SearchResults"
      class="row w-100 position-absolute justify-content-center"
    >
      <div id="SearchResultsWrapper" class="col-10 col-md-8">
        <div id="SearchResultsInnerWrapper" class="posistion-absolute w-100">
          <SearchResults
            @search-item-mouse-over="mouseOverItem"
            @search-item-mouse-leave="mouseLeaveItem"
            @search-item-on-click="mouseClickItem"
            @scroll-in-view="scrollInView"
          />
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        id="SearchBar"
        class="col-10 col-md-8 border position-relative bg-light"
      >
        <form class="d-flex flex-column align-content-between flex-wrap">
          <div id="inputRow" class="d-flex flex-row bg-white input-group">
            <input
              id="TextSearchInput"
              type="text"
              :class="[
                'form-control',
                (!isValid && showValid) | notFound ? 'is-invalid' : '',
              ]"
              v-model="input"
              @keyup="partialSearch"
              placeholder="Port search"
              aria-label="Port search"
              required
            />
            <button
              id="TextResetIcon"
              type="reset"
              class="btn btn-block fa fa-times"
              @click="clearList"
            />
            <button
              id="TextSearchIcon"
              type="submit"
              @click="fullSearch"
              class="btn btn-block fa fa-search text-secondary"
            />
          </div>
          <span
            id="loadingSpinner"
            class="fas fa-spinner fa-spin text-secondary position-absolute"
            v-if="loading"
          />
          <div
            id="checkRow"
            class="w-100 justify-content-center overflow-scroll"
          >
            <div class="form-check form-check-inline form-switch">
              <input
                v-model="mapSearch"
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckChecked"
              />
              <label class="form-check-label" for="flexSwitchCheckChecked"
                >Map search</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                v-model="searchCategory"
                name="portCategoryOptions"
                id="portRadio"
                value="port"
                checked
              />
              <label class="form-check-label" for="portRadio">Port</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                v-model="searchCategory"
                name="portCategoryOptions"
                id="airportRadio"
                value="airport"
              />
              <label class="form-check-label" for="airportRadio">Airport</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                v-model="searchCategory"
                name="portCategoryOptions"
                id="seaportRadio"
                value="seaport"
              />
              <label class="form-check-label" for="seaportRadio">Seaport</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                v-model="searchCategory"
                name="portCategoryOptions"
                id="unlocodeRadio"
                value="unlocode"
              />
              <label class="form-check-label" for="unlocodeRadio"
                >un/locode</label
              >
            </div>
            <div id="customRadioDiv" class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                v-model="searchCategory"
                name="portCategoryOptions"
                id="customRadio"
                value="custom"
              />
              <label
                id="customDropdownButton"
                class="form-check-label dropdown-toggle"
                for="customRadio"
                @click="toggleDropdown"
              >
                Custom
              </label>
              <ul
                id="dropdownList"
                :class="['dropdown-menu', customDropdown ? 'd-block' : '']"
              >
                <CustomDropdownItem
                  category="large_airport"
                  @checkbox-event="setCustomCheckbox"
                />
                <CustomDropdownItem
                  category="medium_airport"
                  @checkbox-event="setCustomCheckbox"
                />
                <CustomDropdownItem
                  category="small_airport"
                  @checkbox-event="setCustomCheckbox"
                />
                <CustomDropdownItem
                  category="heliport"
                  @checkbox-event="setCustomCheckbox"
                />
                <CustomDropdownItem
                  category="seaplane_base"
                  @checkbox-event="setCustomCheckbox"
                />
                <CustomDropdownItem
                  category="balloonport"
                  @checkbox-event="setCustomCheckbox"
                />
                <CustomDropdownItem
                  category="closed"
                  @checkbox-event="setCustomCheckbox"
                />
                <li><hr class="dropdown-divider my-0" /></li>
                <CustomDropdownItem
                  category="seaport"
                  @checkbox-event="setCustomCheckbox"
                />
                <CustomDropdownItem
                  category="multi_modal"
                  @checkbox-event="setCustomCheckbox"
                />
                <CustomDropdownItem
                  category="airport"
                  @checkbox-event="setCustomCheckbox"
                />
                <CustomDropdownItem
                  category="rail_terminal"
                  @checkbox-event="setCustomCheckbox"
                />
                <CustomDropdownItem
                  category="border_crossing"
                  @checkbox-event="setCustomCheckbox"
                />
                <CustomDropdownItem
                  category="postal_exchange_office"
                  @checkbox-event="setCustomCheckbox"
                />
                <CustomDropdownItem
                  category="fixed_transport"
                  @checkbox-event="setCustomCheckbox"
                />
                <CustomDropdownItem
                  category="road_terminal"
                  @checkbox-event="setCustomCheckbox"
                />
                <CustomDropdownItem
                  category="unknown"
                  @checkbox-event="setCustomCheckbox"
                />
              </ul>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SearchResults from "./SearchResults.vue";
import CustomDropdownItem from "./CategoryDropdownItem";

export default {
  name: "SearchBar",
  components: {
    SearchResults,
    CustomDropdownItem,
  },
  computed: {
    isValid() {
      return this.input.length > 2;
    },
  },
  data() {
    return {
      showValid: false,
      input: "",
      searchCategory: "port",
      mapSearch: true,
      customDropdown: false,
      customInput: null,
    };
  },
  emits: [
    "search-item-mouse-over",
    "search-item-mouse-leave",
    "search-item-on-click",
    "partial-search",
    "full-search",
    "scroll-in-view",
    "clear-list",
    "allow-map-search",
  ],
  props: ["loading", "notFound"],
  methods: {
    mouseOverItem(index) {
      this.$emit("search-item-mouse-over", index);
    },
    mouseLeaveItem(index) {
      this.$emit("search-item-mouse-leave", index);
    },
    mouseClickItem(index) {
      this.$emit("search-item-on-click", index);
    },
    scrollInView(index) {
      this.$emit("scroll-in-view", index);
    },
    toggleDropdown() {
      // e.preventDefault();
      this.customDropdown = !this.customDropdown;
    },
    clearList(e) {
      e.preventDefault();
      this.input = "";
      this.$emit("clear-list");
    },
    setCustomCheckbox(category) {
      if (this.customInput.has(category)) {
        this.customInput.delete(category);
      } else {
        this.customInput.add(category);
      }
    },
    checkInputLength() {
      if (this.input.length === 0) {
        this.inputValid = true;
        return false;
      } else {
        this.mapSearch = false;
        if (this.input.length > 2) {
          this.inputValid = true;
          return true;
        }
        this.inputValid = false;
        return false;
      }
    },
    partialSearch(e) {
      if (e.key === "Escape") {
        this.input = "";
        this.clearList(e);
      }
      this.checkInputLength();
      if (this.checkInputLength()) {
        this.showValid = false;
        this.$emit("partial-search", {
          input: this.input,
          category: this.searchCategory,
        });
      }
    },
    fullSearch(e) {
      e.preventDefault();
      if (this.checkInputLength()) {
        this.showValid = false;
        this.$emit("full-search", {
          input: this.input,
          category: this.searchCategory,
        });
      } else {
        this.showValid = true;
      }
    },
  },
  mounted() {
    this.customInput = new Set();
    // close custom dropdown when clicked outside
    var self = this;
    document.addEventListener("mouseup", function(e) {
      var customRadioDiv = document.getElementById("customRadioDiv");
      if (!customRadioDiv.contains(e.target) && self.customDropdown) {
        self.customDropdown = false;
      }
    });
  },
  watch: {
    mapSearch: {
      handler: function() {
        this.$emit("allow-map-search", this.mapSearch);
      },
    },
  },
};
</script>

<style>
#SearchBar {
  top: -40px;
  height: 80px;
  z-index: 99;
  border-radius: 40px;
}
#SearchResultsWrapper {
  z-index: 98;
  padding: 0px 40px 0;
}
#SearchResultsInnerWrapper {
  height: 10px;
  padding-top: 40px;
}
#SearchButton {
  top: 20px;
  height: 40px;
  width: 40px;
}
#inputRow {
  height: 40px;
  margin: 5px 0;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}
#TextSearchInput {
  height: 40px;
  border: none;
  padding: 0 5px 0 20px;
  border-radius: 20px 0 0 20px;
}
#TextSearchIcon {
  height: 40px;
  padding: 0 20px 0 10px;
  border-radius: 0 20px 20px 0;
}
#TextResetIcon {
  height: 40px;
  padding: 0 10px 0;
  border-radius: 0;
  color: #a6abb1;
}
#loadingSpinner {
  left: 15px;
  top: 15px;
  margin: 5px;
  font-size: xx-small;
  z-index: 100;
}
#checkRow {
  height: 30px;
  padding-top: 3px;
  font-size: small;
}
#checkRow::-webkit-scrollbar {
  display: none;
}
/* #checkRow:hover::-webkit-scrollbar {
  display: inline;
} */
#customDropdownButton {
  border: none;
  background: none;
  /* pointer-events: none; */
}
#dropdownList {
  min-width: 7rem;
  max-height: 30vh;
  overflow: scroll;
  /* columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2; */
}
</style>
