<template>
  <div id="mapContainer" class="" />
  <div
    v-if="zoomBig | tooManyResults"
    id="mapWarningWrapper"
    class="d-flex position-absolute w-100 justify-content-center"
  >
    <div v-if="mapSearch & (zoomBig | tooManyResults)" id="mapWarning" class="position-relative bg-secondary text-light px-3">
      Zoom in for map search
    </div>
    <div v-if="!mapSearch & tooManyResults" id="mapWarning" class="position-relative bg-secondary text-light px-3">
      Too many results
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { icon_type } from "../utils.js";

export default {
  name: "Map",
  props: ["tooManyResults", "mapSearch"],
  inject: ["geoJSON"],
  data() {
    return {
      map: null,
      geoLayer: null,
      zoomBig: true,
    };
  },
  emits: [
    "map-item-on-click",
    "map-item-mouse-over",
    "map-item-mouse-left",
    "map-move",
  ],
  methods: {
    log(str) {
      console.log(str);
    },
    iconUrl(properties) {
      var ico_type = icon_type(properties);
      var color_str = properties.selected
        ? "pink"
        : properties.hover
        ? "blue"
        : "dark";
      return require(`../assets/icons/marker/${color_str}/${color_str}_${ico_type}_marker.svg`);
    },
    customIcon(type) {
      var url = this.iconUrl(type);
      return L.icon({
        iconUrl: url,
        // shadowUrl: "leaf-shadow.png",

        iconSize: [80, 100], // size of the icon
        // shadowSize: [50, 64], // size of the shadow
        iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
        // shadowAnchor: [4, 62], // the same for the shadow
        popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
      });
    },
    emitClick(feature) {
      var item = this.geoJSON.features.find((i) => i.hashID === feature.hashID);
      this.$emit("map-item-on-click", item.hashID);
    },
    emitMouseOver(feature) {
      var item = this.geoJSON.features.find((i) => i.hashID === feature.hashID);
      this.$emit("map-item-mouse-over", item.hashID);
    },
    emitMouseLeft(feature) {
      var item = this.geoJSON.features.find((i) => i.hashID === feature.hashID);
      this.$emit("map-item-mouse-left", item.hashID);
    },
    reset_map() {
      // this.map.fitBounds(this.geoLayer.getBounds());
      this.map.flyToBounds(this.geoLayer.getBounds());
    },
    pan_to(coordinates) {
      this.map.flyTo(new L.LatLng(coordinates[1], coordinates[0]), 12);
    },
  },
  mounted() {
    var self = this;
    const mymap = L.map("mapContainer").setView([52, 0], 5);
    L.tileLayer(
      "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
      {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
        subdomains: "abcd",
        maxZoom: 15,
        minZoom: 2,
      }
    ).addTo(mymap);
    this.geoLayer = L.geoJSON(this.geoJSON);
    this.geoLayer.addTo(mymap);
    mymap.on("moveend", function() {
      if (mymap.getZoom() > 8) {
        var bounds = mymap.getBounds();
        self.zoomBig = false;
        self.$emit("map-move", bounds);
      } else {
        self.zoomBig = true;
      }
    });
    this.map = mymap;
  },
  watch: {
    geoJSON: {
      handler: function() {
        var self = this;
        if (this.geoLayer) {
          this.map.removeLayer(this.geoLayer);
        }
        this.geoLayer = L.geoJSON(this.geoJSON, {
          pointToLayer: function(feature, latlng) {
            var iconUrl = self.iconUrl(feature.properties);
            var icon = L.icon({
              iconUrl: iconUrl,
              iconSize: [40, 50],
              iconAnchor: [20, 50],
              popupAnchor: [0, 0],
            });
            return L.marker(latlng, { icon: icon });
            // .bindTooltip(
            //   feature.properties.name,
            //   { opacity: 0.7 }
            // );
          },
          onEachFeature: function(feature, layer) {
            layer.on("click", function() {
              self.emitClick(feature);
            });
            layer.on("mouseover", function() {
              self.emitMouseOver(feature);
            });
            layer.on("mouseout", function() {
              self.emitMouseLeft(feature);
            });
          },
        });
        this.geoLayer.addTo(this.map);
      },
      deep: true,
    },
  },
};
</script>

<style>
#mapContainer {
  height: calc(100vh - 40px);
  z-index: 2;
}
#mapWarningWrapper {
  z-index: 3;
  height: 0;
}
#mapWarning {
  top: -40px;
  height: 25px;
  border-radius: 12.5px;
}
</style>
