<template>
  <li class="px-1">
    <div class="form-check dropdown-div">
      <input
        class="form-check-input dropdown-input"
        type="checkbox"
        :value="category"
        :id="category"
        v-on:input="checkboxVal"
      />
      <label class="form-check-label dropdown-label" :for="category">
        {{ category.replace(/([^a-zA-Z])/g, " ") }}
      </label>
    </div>
  </li>
</template>

<script>
export default {
  name: "CustomDropdownItem",
  props: ["category"],
  methods: {
    checkboxVal() {
      this.$emit("checkbox-event", this.category);
    },
  },
};
</script>

<style>
.dropdown-input
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(.7); /* IE */
  -moz-transform: scale(.7); /* FF */
  -webkit-transform: scale(.7); /* Safari and Chrome */
  -o-transform: scale(.7); /* Opera */
  transform: scale(.7);
  margin: 0;
}
.dropdown-div {
    display: flex;
    margin-bottom: 0;
    height: 20px;
    align-items: center;
}
.dropdown-label {
    font-size: x-small;
    padding: 0 5px 0;
}
</style>
